.Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
    > h1 {
        margin-bottom: 0;
        font-weight: normal;
    }
    > p {
        margin-top: 8px;
    }
    &:empty { display: none; }
}

.Featured {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: visible;
    padding-left: 0;
    > li {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style-type: none;
        margin: 0 var(--spacing-small-half);
        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }
        text-align: center;
    }
}
