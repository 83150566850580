.ComponentBodyFigure {

    display: block !important;
    margin: 0 0 var(--spacing-small) 0;
    padding: 0 !important;

    @media ( min-width: 600px ) {

        &[data-preferred-alignment='Left'] {
            float: left;
            margin-left: 0;
            margin-right: var(--spacing-large);
        }
        &[data-preferred-alignment='Right'] {
            float: right;
            margin-left: var(--spacing-medium);
            margin-right: 0;
            text-align: right;
        }

        &[data-preferred-width='Full'] {
            max-width: 100%;
            float: none !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        &[data-preferred-width='Half'] {
            max-width: 50%;
        }
        &[data-preferred-width='Quarter'] {
            max-width: 25%;
        }

    }

    img {
        margin-bottom: var(--spacing-small-half)
    }

    font-size: var(--font-size-small);

    figcaption {
        color: hsl(0 0% 70%);
        > :last-child { padding-bottom: 0; }
    }

}
