.JobVacancyList {

    display: flex;
    flex-direction: row;
    align-items: center;

    > header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2em;
        &:empty { display: none; }
        > h1 {
            font-size: 4em;
            margin-bottom: 0;
            font-size: 1.5em;
            line-height: 1.2em;
            font-weight: normal;
        }
        > p {
            margin-top: 8px;
        }
    }

    > ul {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        padding-left: 0;
        > li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            list-style-type: none;
            margin: 1em;
        }
    }

}
