.ComponentBodyFeaturedList {

    display: flex;
    flex-direction: column;
    align-items: stretch;

    > header {
        min-width: 100%;
        padding: 0 0 var(--spacing-small) 0;
    }

    > ul {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-small);
        > li {
            padding: 0;
            margin: 0;
            width: 100%;
            border-bottom: 1px solid var(--colour-text-accent-primary);
            &:first-child:last-child { max-width: var(--card-max-width); }
        }
    }

}

[data-type='Hero_Image'] + .ComponentBodyFeaturedList {
    padding: 0 calc( var(--spacing-large) - var(--spacing-small) );
    --background: var(--colour-background-primary) !important;
    > ul {
        margin-top: calc( -1 * var(--spacing-small) );
        z-index: var(--layer-body-high);
        background: var(--background);
        padding: var(--spacing-small);
    }
}
