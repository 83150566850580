.Featured {

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: var(--font-size-medium);
    }

    hr {
        width: 100%;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: var(--colour-text-accent-primary);
        margin-top: var(--spacing-small-three-quarters);
        margin-bottom: var(--spacing-small-half);
    }

    [data-type='Expanded'] & {

        padding: var(--spacing-medium) var(--page-padding-horizontal);

        display: grid;
        
        @media ( min-width: 0px ) {
            grid-template-columns: 1fr;
            gap: var(--spacing-small) var(--spacing-large);
        }
        @media ( min-width: 1300px ) {
            grid-template-columns: 1fr 1fr;
            gap: var(--spacing-medium) var(--spacing-large);
        }

        > a {
            grid-area: image;
            position: relative;
            z-index: 2;
            max-height: 100%;
            max-width: calc(100vw - 2 * var(--page-padding-horizontal));
            @media ( max-width: 1299px ) {
                picture, img { max-height: 350px; }
            }
            filter: var(--filter-shadow-mid);
            > :not(picture,img) { display: none; }
        }

        > section {
            grid-area: text;
            position: relative;
            z-index: 2;
        }

    }

    [data-type='Expanded'] :nth-child(odd) > & {
        @media ( min-width: 0px ) {
            grid-template-areas:
                "text"
                "image";
            //grid-template-rows: min-content 350px;
            //margin-bottom: calc( -1 * var(--spacing-medium) );
            &::before { display: none; }
            > a::before {
                display: block;
                top: calc( -2 * var(--spacing-medium) );
                left: calc( -1 * var(--page-padding-horizontal) );
                right: calc( -1 * var(--page-padding-horizontal) );
                height: calc( 50% + 2 * var(--spacing-medium) );
            }
            > section::before {
                display: block;
                top: calc( -1 * var(--spacing-medium) );
                left: calc( -1 * var(--page-padding-horizontal) );
                right: calc( -1 * var(--page-padding-horizontal) );
                height: calc( 100% + 2 * var(--spacing-medium) );
            }
        }
        @media ( min-width: 1300px ) {
            grid-template-areas: "text image";
            grid-template-rows: 1fr;
            margin-bottom: 0;
            &::before {
                display: block;
                top: 0;
                left: 0;
                bottom: 0;
                width: 75%;
            }
            > a::before { display: none; }
            > section::before { display: none; }
        }
        &::before,
        > a::before,
        > section::before {
            content: '';
            position: absolute;
            z-index: var(--layer-background);
            background: var(--colour-background-accent-primary);
        }
        h1 > a { color: var(--colour-text-secondary); }
        > section {
            color: var(--colour-text-tertiary);
        }
        section > a {
            --colour-link: var(--colour-text-tertiary);
            --colour-link-active: var(--colour-text-accent-primary);
        }
        svg { filter: opacity(0.6); }
    }

    [data-type='Expanded'] :nth-child(even) > & {
        @media ( min-width: 0px ) {
            grid-template-areas:
                "image"
                "text";
            //grid-template-rows: 350px min-content;
            gap: var(--spacing-medium);
        }
        @media ( min-width: 1300px ) {
            grid-template-areas: "image text";
            grid-template-rows: 1fr;
            gap: var(--spacing-medium) var(--spacing-large);
            padding-bottom: var(--spacing-large);
        }
        padding-bottom: calc( var(--spacing-large) + var(--spacing-small) );
        a { overflow: visible; }
        > a::before {
            content: '';
            display: block;
            position: absolute;
            z-index: var(--layer-background);
            background: var(--colour-background-accent-primary);
            width: 100%;
            height: 100%;
            left: calc( -1 * var(--spacing-small) );
            bottom: calc( -1 * var(--spacing-small) );
        }
        section > a {
            --colour-link: var(--colour-text-primary);
            --colour-link-active: var(--colour-text-accent-primary);
        }
        svg { filter: opacity(0.8); }
    }

}

.Image {

    position: relative;
    aspect-ratio: 3 / 2;

    > picture,
    > img {
        position: relative;
    }

    [data-type='Slider'] & {
        width: calc( 6 * var(--spacing-medium) );
        filter: var(--filter-shadow-mid);
    }

    [data-type='Expanded'] & {
        width: 100%;
    }

}

.Summary {

    [data-type='Slider'] & {
        display: none;
    }

    [data-type='Expanded'] & {

        display: grid;

        @media( min-width: 0px ) {
            grid-template-areas:
                'logo'
                'title'
                'summary'
        }
        @media( min-width: 350px ) {
            grid-template-areas:
                'logo title'
                'summary summary';
            grid-template-columns: min-content 1fr;
        }
        @media( min-width: 525px ) {
            grid-template-areas:
                'logo title'
                'logo summary';
        }
        
        justify-items: start;
        align-items: start;

        > div {
            grid-area: logo;
            align-self: start;
        }

        > h1 {
            grid-area: title;
            align-self: end;
            margin: 0;
            > a {
                font-size: var(--font-size-featured);
                line-height: 1.2em;
                font-weight: normal;
            }
            &::after {
                display: block;
                content: '';
                width: var(--spacing-medium);
                margin-top: var(--spacing-small-half);
                border-bottom: 1px solid var(--colour-text-accent-primary);
            }
        }

        > section {
            grid-area: summary;
            font-size: var(--font-size-large);
            > a {
                width: min-content;
                white-space: nowrap;
            }
        }

    }

}

.Logo {
    margin-right: var(--spacing-small);
    width: 120px !important;
    height: 120px !important;
}
