.Article {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    > a {
        font-size: 16px;
    }
    p { margin: 0; }

    h1 {
        margin: 0;
        font-size: var(--font-size-large);
    }

    table {
        margin: 1em 0;
    }

    th, td {
        vertical-align: top;
    }

    th {
        padding: 0;
        white-space: nowrap;
    }
    td {
        padding: 0 0 0 1em;
    }

    @media( max-width: 350px ) {
        table, tbody, tr {
            display: flex;
            flex-direction: column;
        }
    }

    section {
        flex-grow: 1;
    }

    > a:last-child {
        // NOTE: This is duplicated from the button module
        display: inline-block;
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        flex: 0 0 auto;
        padding: 6px 14px 4px 14px;
        margin: 0 1em 0 0 ;
        color: var(--colour-link);
        border: 1px solid var(--colour-link);
        transition: all 0.35s ease-in-out;
        &:hover {
            border: 1px solid var(--colour-link-active);
            color: var(--colour-link-active);
            transition: all 0.35s ease-in-out;
            filter: drop-shadow(0 0 8px var(--colour-link-glow));
        }
    }

}
