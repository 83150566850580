.Browser {

    padding: var(--spacing-small) var(--page-padding-horizontal) var(--spacing-medium) var(--page-padding-horizontal);

    > ul {
        display: grid;
        @media( min-width: 0px ) {
            grid-template-columns: 1fr;
        }
        @media( min-width: 900px ) {
            grid-template-columns: repeat(auto-fit,minmax(400px,1fr));
        }
        gap: var(--spacing-small);
        list-style-type: none;
        padding-left: 0;
        filter: var(--filter-shadow-mid);
        > li {
            display: flex;
            flex-direction: column;
            background: var(--colour-background-primary);
            padding: var(--spacing-small);
            border-radius: var(--border-radius-medium);
            height: 100%;
        }
    }

}
