@use 'sass:color';

.ComponentBodyHeroArea {

    position: relative;
    min-height: min-content;

    display: grid;
    gap: 0;
    padding: 0 !important;
    overflow: hidden;

    border-top: none !important;

    @media ( max-width: 699px) {
        grid-template-areas:
            "body"
            "image";
        grid-template-columns: 100%;
        grid-template-rows: 1fr 0;

        background: linear-gradient(
            to top,
            hsla(0 0% 0% / 85% ) 0%,
            hsla(0 0% 0% / 85% ) 30%,
            hsla(0 0% 0% / 0% ) 100%
        ) !important;

    }

    //&[data-type='MEE_Half_Image'] { background: $mee-colour-1 !important; }

    @media ( min-width: 700px ) {

        grid-template-areas:
            "body image";

        [data-site='main'] & { grid-template-columns: 60% 40%; }
        &[data-type='MEE_Full_Bleed'] { grid-template-columns: 50% 50%; }

        grid-template-rows: 1fr;
        padding-top: 0;

        &[data-has-content='true'] {
            min-height: 50vh;
        }

        &[data-height='Tall'][data-has-content='true'] {
            min-height: 70vh;
        }

        &[data-height='Medium'][data-has-content='true'] {
            min-height: 50vh;
        }

        &[data-height='Short'][data-has-content='true'] {
            min-height: 30vh;
            [data-site='events'] & {
                min-height: 20vh;
            }
        }

        &[data-type='MEE_Half_Image'][data-height='Short'][data-has-content='true'] {
            max-height: 30vh;
        }

    }

    &::after {

        position: absolute;
        z-index: 2;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 0;

        [data-site='main'] & {
            width: 60%;
            background: linear-gradient(
                to right,
                hsla(0 0% 0% / 85% ) 0%,
                hsla(0 0% 0% / 85% ) 60%,
                hsla(0 0% 0% / 0% ) 100%
            ) !important;
        }

    }
    &[data-type='MEE_Full_Bleed']::after {
        width: 100%;
        //background: transparentize($mee-colour-3, 0.2);
        backdrop-filter: grayscale(1);
    }
    &[data-type='Magazines']::after {
        width: 100%;
        background: var(--colour-background-accent-primary);
        backdrop-filter: grayscale(1);
        filter: opacity(0.8);
    }

    > div:first-child {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @media( max-width: 699px ) {
        > :last-child:not(picture) { 
            margin-bottom: calc( 1.5 * var(--spacing-large) ) !important; 
            [data-site='events'] & { margin-bottom: var(--spacing-large) !important; }
        }
    }
    @media( min-width: 700px ) {
        > :last-child:not(picture) { margin-bottom: var(--spacing-large) !important; }
    }

}
