.Frame {
    max-width: 100%;
    max-height: 100%;
}

.Container {
    display: flex;
    flex-direction: row;
    [data-align='right'] {
        margin-left: auto;
    }
    [data-align='center'] {
        margin: auto;
    }
}